import { useTranslation } from 'react-i18next'

export const Footer = () => {
  const { t } = useTranslation()

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__notes">
          <p>
            Powered by
            <a href={'https://www.liip.ch'} target={'_blank'}>
              {' '}
              Liip
            </a>
          </p>
          <p>{t('footer.disclaimer')}</p>
        </div>
        <div className="footer__language">
          <label htmlFor="language-switcher" className="sr-only">
            {t('footer.selectLanguage')}
          </label>
        </div>
      </div>
    </footer>
  )
}
